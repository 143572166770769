import React, { useEffect } from 'react';
import { RouteLoadingSpinner } from './components/UI';
import { Observer, observer, useLocalObservable } from 'mobx-react-lite';
import styled, { ThemeProvider } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { _get, useInjectStores } from 'utils/utils';
import UnauthenticatedApp from './unauthenticated-app';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

const loadAuthenticatedApp = () => import('./authenticated-app');
const AuthenticatedApp = React.lazy(() => loadAuthenticatedApp());

const App = observer(() => {
  const { uiStore, userStore } = useInjectStores();
  const fetchUserData = userStore.fetchUserData,
    pricingData = userStore.fetchPricingData,
    user = _get(userStore, 'user');

  const store = useLocalObservable(() => ({
    isLoading: true,
    setIsLoading(loading) {
      this.isLoading = loading;
    },
    get title() {
      const href = window.location.href;
      if (href.includes('localhost' || '127')) {
        return 'Local Eight';
      }
      if (href.includes('dev')) {
        return 'Dev Eight';
      }
      if (href.includes('staging')) {
        return 'Staging Eight';
      }
      if (href.includes('demo')) {
        return 'Demo Eight';
      }
      if (href.includes('goldeneight')) {
        return 'Eight';
      } else {
        return 'Eight';
      }
    },
  }));

  const filterNavItems = () => {
    const permissions = user.currentClient.permissions;
    Object.keys(permissions).forEach((key) => {
      if(permissions[key] === "Unchecked") {
        if(!key.includes('tab')) {
          switch(key) {
            // Accounting Modules
            case 'acctg_pg_dly_bat':
              user.currentClient.modules.accounting.views.accountingDailyBatches.hide_sidebar = true;
              user.currentClient.modules.accounting.views.accountingDailyBatches.hide_sidebar_dev = true;
              break;
            case 'acctg_pg_dly_dep':
              user.currentClient.modules.accounting.views.dailyDeposits.hide_sidebar = true;
              user.currentClient.modules.accounting.views.dailyDeposits.hide_sidebar_dev = true;
              break;
            case 'acctg_pg_jnl_accl':
              user.currentClient.modules.accounting.views.accountingJournalsAccrual.hide_sidebar = true;
              user.currentClient.modules.accounting.views.accountingJournalsAccrual.hide_sidebar_dev = true;
              break;
            case 'acctg_pg_jnl_cash':
              user.currentClient.modules.accounting.views.accountingJournalsCash.hide_sidebar = true;
              user.currentClient.modules.accounting.views.accountingJournalsCash.hide_sidebar_dev = true;
              break;
            case 'acctg_pg_prepd_bill':
              user.currentClient.modules.accounting.views.accountingPrepaidBilling.hide_sidebar = true;
              user.currentClient.modules.accounting.views.accountingPrepaidBilling.hide_sidebar_dev = true;
              break;
            case 'acctg_pg_tsys_stmt':
              user.currentClient.modules.accounting.views.tsysStatement.hide_sidebar = true;
              user.currentClient.modules.accounting.views.tsysStatement.hide_sidebar_dev = true;
              break;
            // Fees Modules
            case 'fees_pg_fee_by_cat':
              user.currentClient.modules.fees.views.feesDetails.hide_sidebar = true;
              user.currentClient.modules.fees.views.feesDetails.hide_sidebar_dev = true;
              break;
            // Admin Modules
            case 'admin_pg_merch':
              user.currentClient.modules.administration.views.adminMerchSettings.hide_sidebar = true;
              user.currentClient.modules.administration.views.adminMerchSettings.hide_sidebar_dev = true;
              break;
            case 'admin_pg_user':
              user.currentClient.modules.administration.views.adminMerchSettings.hide_sidebar = true;
              user.currentClient.modules.administration.views.adminMerchSettings.hide_sidebar_dev = true;
              break;
            case 'user_upg_permsn':
                user.currentClient.modules.administration.views.adminPricing.hide_sidebar = true;
                user.currentClient.modules.administration.views.adminPricing.hide_sidebar_dev = true;
                break;
            // Opportunity Modules
//            case 'opp_pg_dtl':
//              user.currentClient.modules.opportunity.views.opportunityDetails.hide_sidebar = true;
//              user.currentClient.modules.opportunity.views.opportunityDetails.hide_sidebar_dev = true;
//              break;
            case 'opp_pg_sum':
              user.currentClient.modules.opportunity.views.opportunitySummary.hide_sidebar = true;
              user.currentClient.modules.opportunity.views.opportunitySummary.hide_sidebar_dev = true;
              break;
            // Tax Modules
            case 'tax_pg_1099k':
              user.currentClient.modules.tax.views.tax1099K.hide_sidebar = true;
              user.currentClient.modules.tax.views.tax1099K.hide_sidebar_dev = true;
              break;
            // Transaction Modules
            case 'txns_pg_analyt':
              user.currentClient.modules.transactions.views.transactionsAnalytics.hide_sidebar = true;
              user.currentClient.modules.transactions.views.transactionsAnalytics.hide_sidebar_dev = true;
              break;
            case 'txns_pg_chgbk':
              user.currentClient.modules.transactions.views.transactionsChargebacks.hide_sidebar = true;
              user.currentClient.modules.transactions.views.transactionsChargebacks.hide_sidebar_dev = true;
              break;
            case 'txns_pg_decl':
              user.currentClient.modules.transactions.views.transactionsDeclines.hide_sidebar = true;
              user.currentClient.modules.transactions.views.transactionsDeclines.hide_sidebar_dev = true;
              break;
            case 'txns_pg_recon':
              user.currentClient.modules.transactions.views.reconSummary.hide_sidebar = true;
              user.currentClient.modules.transactions.views.reconSummary.hide_sidebar_dev = true;
              break;
            case 'txns_pg_ref':
              user.currentClient.modules.transactions.views.transactionsRefunds.hide_sidebar = true;
              user.currentClient.modules.transactions.views.transactionsRefunds.hide_sidebar_dev = true;
              break;
            case 'txns_pg_sale':
              user.currentClient.modules.transactions.views.transactionsSales.hide_sidebar = true;
              user.currentClient.modules.transactions.views.transactionsSales.hide_sidebar_dev = true;
              break;
            case 'txns_pg_voidinq':
              user.currentClient.modules.transactions.views.voidsTransactions.hide_sidebar = true;
              user.currentClient.modules.transactions.views.voidsTransactions.hide_sidebar_dev = true;
              break;  
          }
        }
      }
    });
    Object.keys(permissions).forEach((key) => {
      if(permissions[key] === "Unchecked") {
        if(key.includes('tab')) {
          switch(key) {
            // If the tab is unchecked we can remove all the views
            case 'dshbrd_tab':
              user.currentClient.modules.dashboard.views = null;
              break;
            case 'acctg_tab':
              user.currentClient.modules.accounting.views = null;
              break;
            case 'admin_tab':
              user.currentClient.modules.administration.views = null;
              break;
            case 'txns_tab':
              user.currentClient.modules.transactions.views = null;
              break;
            case 'tax_tab':
              user.currentClient.modules.tax.views = null;
              break;
            case 'reps_tab':
              user.currentClient.modules.reports.views = null;
              break;
            case 'opp_tab':
              user.currentClient.modules.opportunity.views = null;
              break;
            case 'fkyc_tab':
              user.currentClient.modules.fkyc.views = null;
              break;
            case 'fees_tab':
              user.currentClient.modules.fees.views = null;
              break;
          }
          // End of tab removal
        }
      }
    });
  };

  useEffect( () => {
    async function loadApp() {
      store.setIsLoading(true);
      let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      document.title = store.title;
      await loadAuthenticatedApp();
      await fetchUserData();
      store.setIsLoading(false);
    }

    async function loadPricingData() {
      await pricingData();
    }
    
    if (!user) {
      loadApp();
    }else {
      // loadPricingData();
      filterNavItems();
    }
  }, [user]);

  return (
    <Observer>
      {() => (
        <ThemeProvider theme={uiStore.currentTheme}>
          <Background>
            <Router>
              <Switch>
                {store.isLoading ? (
                  <RouteLoadingSpinner size={'400px'} />
                ) : !user ? (
                  <UnauthenticatedApp />
                ) : (
                  <React.Suspense fallback={<RouteLoadingSpinner size={'400px'} />}>
                    <AuthenticatedApp />
                  </React.Suspense>
                )}
              </Switch>
            </Router>
          </Background>
          <ToastContainer />
        </ThemeProvider>
      )}
    </Observer>
  );
});

const Background = styled.div`
  position: fixed;
  display: flex;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background: ${({ theme }) => theme.background};
`;

export default App;
